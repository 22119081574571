import type { DynamicHelpConfig } from '../../default/cms/dynamicHelp.types';

export const dynamicHelp: DynamicHelpConfig = {
  labelMessageKey: 'forms.type.subject.label',
  items: [
    { messageKeySelectOption: 'forms.contact.dynamicSubject.order' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.datasecurity' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.web' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.account' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.personalData' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.praemie' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.product' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.complaint' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.complaint.returnsprocessing' },
  ],
};
