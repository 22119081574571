import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  voucherPrice: {
    enabled: false,
  },
  cliplister: {
    enabled: false,
  },
  aplus: {
    enabled: false,
  },
  hasScarcity: false,
  hasSwogo: false,
  powerSupply: {
    pictogram: {
      included: 'https://bilder.imwalking.de/i/empiriecom/FuAG_included.svg',
      notIncluded: 'https://bilder.imwalking.de/i/empiriecom/FuAG_not_included.svg',
      specification: 'https://bilder.imwalking.de/i/empiriecom/FuAG_specifications.svg',
    },
  },
};
